export const translations = {
  es: {
    title: "Aviso Legal",
    paragraph1: "En cumplimiento del art. 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSI+CE), se le informa: La empresa titular de la página https://kolibird.app",
    paragraph2: "Nombre Comercial: KOLIBIRD APP",
    paragraph3: "Domicilio Social: Vía de las Dos Castillas 7, 28224 Pozuelo de Alarcón.",
    paragraph4: "Teléfono: 675493668",
    paragraph5: "El presente Aviso Legal se encuentra redactado en idioma Castellano.",
    paragraph6: "Sus Datos",
    paragraph7: "La información o datos personales que el usuario facilite serán tratados con arreglo a lo establecido en las Políticas de Protección de Datos. Al hacer uso de esta página web el usuario consiente el tratamiento de dicha información y datos y declara que toda la información o datos que sean facilitados son veraces y se corresponden con la realidad.",
    paragraph8: "Privacidad/Protección de datos personales",
    paragraph9: "KOLIBIRD.APP cumple con las directrices Reglamento General de Protección de datos (Reglamento (UE) 2016/679) y demás normativa vigente en cada momento, y vela por garantizar un correcto uso y tratamiento de los datos personales del usuario.",
    paragraph10: "Para ello, junto a cada formulario de recabo de datos de carácter personal, en los servicios que el usuario pueda solicitar a Nombre de la empresa creadora del sitio web , hará saber al usuario de la existencia y aceptación de las condiciones particulares del tratamiento de sus datos en cada caso, informándole de la responsabilidad del fichero creado, la dirección del responsable, la posibilidad de ejercer sus derechos de acceso, rectificación, cancelación u oposición, la finalidad del tratamiento y las comunicaciones de datos a terceros en su caso.",
    paragraph11: "Asimismo, KOLIBIRD APP creadora del sitio web informa que da cumplimiento a la Ley 34/2002 de 11 de julio, de Servicios de la Sociedad de la Información y el Comercio Electrónico y solicitará al usuario su consentimiento al tratamiento de su correo electrónico con fines comerciales en cada momento.",
    paragraph12: "Menores de edad",
    paragraph13: "The contents of the Website are intended for persons of legal age. KOLIBIRD APP does not intend to obtain data from minors. When KOLIBIRD APP becomes aware of the existence in its file of personal data of a minor, it will immediately proceed to cancel such data",
    paragraph14: "Obligaciones del usuario",
    paragraph15: "El Usuario se compromete a utilizar la Web de conformidad a la Ley y al presente Aviso Legal, así como a la moral y a buenas costumbres. A tal efecto, el Usuario se abstendrá de utilizar la página con finalidades ilícitas o prohibidas, lesivas de derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar, sobrecargar, deteriorar o impedir la normal utilización de equipos informáticos o documentos, archivos y toda clase de contenidos almacenados en cualquier equipo informático del prestador.",
    paragraph16: "En particular, y a título indicativo, pero no exhaustivo, el Usuario se compromete a no transmitir, difundir o poner a disposición de terceros informaciones, datos, contenidos, mensajes, gráficos, dibujos, archivos de sonido o imagen, fotografías, grabaciones, software y, en general, cualquier clase de material que:",
    paragraph17: "Sea contraria, desprecie o atente contra los derechos fundamentales y las libertades públicas reconocidas constitucionalmente, en tratados internacionales y otras normas vigentes.",
    paragraph18: "Induzca, incite o promueva actuaciones delictivas, denigrantes, difamatorias, violentas o, en general, contrarias a la ley, a la moral y al orden público.",
    paragraph19: "Induzca, incite o promueva actuaciones, actitudes o pensamientos discriminatorios por razón de sexo, raza, religión, creencias, edad o condición",
    paragraph20: "Sea contrario al derecho al honor, a la intimidad personal o familiar o a la propia imagen de las personas.",
    paragraph21: "De cualquier manera, perjudique la credibilidad del prestador o de terceros.",
    paragraph22: "Constituya publicidad ilícita, engañosa o desleal",
    paragraph23: "Propiedad Industrial e Intelectual",
    paragraph24: "KOLIBIRD APP por sí o como cesionaria, es titular de todos los derechos de propiedad intelectual e industrial de su página web, así como de los elementos contenidos en la misma (a título enunciativo, imágenes, sonido, audio, vídeo, software o textos; marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.), titularidad de KOLIBIRD APP o bien de sus licenciantes.",
    paragraph25: "En virtud de lo dispuesto en los artículos 8 y 32.1, párrafo segundo, de la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización de KOLIBIRD APP Todos los derechos reservados.",
    paragraph26: "El USUARIO se compromete a respetar los derechos de Propiedad Intelectual e Industrial titularidad de KOLIBIRD APP Podrá visualizar los elementos del portal e incluso imprimirlos, copiarlos y almacenarlos en el disco duro de su ordenador o en cualquier otro soporte físico siempre y cuando sea, única y exclusivamente, para su uso personal y privado. El USUARIO deberá abstenerse de suprimir, alterar, eludir o manipular cualquier dispositivo de protección o sistema de seguridad que estuviera instalado en las páginas de KOLIBIRD APP.",
    paragraph27: "Derecho de exclusión",
    paragraph28: "KOLIBIRD APP se reserva el derecho a denegar o retirar el acceso a portal y/o los servicios ofrecidos sin necesidad de preaviso, a instancia propia o de un tercero, a aquellos usuarios que incumplan las Condiciones de Uso del portal. KOLIBIRD APP perseguirá el incumplimiento de las Condiciones de Uso del portal, así como cualquier utilización indebida de su portal ejerciendo todas las acciones civiles y penales que le puedan corresponder en derecho.",
    paragraph29: "Modificaciones y enlaces",
    paragraph30: "KOLIBIRD APP se reserva el derecho de efectuar sin previo aviso las modificaciones que considere oportunas en su sitio web, pudiendo cambiar, suprimir o añadir tanto los contenidos y servicios que presta como la forma en la que éstos aparezcan presentados o localizados, así como en las Condiciones Generales de Contratación aquí determinadas.",
    paragraph31: "KOLIBIRD APP no se responsabiliza del mal uso que se realice de los contenidos de su página web, siendo exclusiva responsabilidad de la persona que accede a ellos o los utiliza.",
    paragraph32: "En el caso de que en https://kolibird.app/ se dispusiesen enlaces o hipervínculos hacía otros sitios de Internet, KOLIBIRD APP creadora del sitio web no ejercerá ningún tipo de control sobre dichos sitios y contenidos. En ningún caso KOLIBIRD APP creadora del sitio web asumirá responsabilidad alguna por los contenidos de algún enlace perteneciente a un sitio web ajeno, ni garantizará la disponibilidad técnica, calidad, fiabilidad, exactitud, amplitud, veracidad, validez y constitucionalidad de cualquier material o información contenida en ninguno de dichos hipervínculos u otros sitios de Internet. Igualmente, la inclusión de estas conexiones externas no implicará ningún tipo de asociación, fusión o participación con las entidades conectadas.",
    paragraph33: "Ley aplicable y jurisdicción competente",
    paragraph34: "Las condiciones de uso de la Página Web contenidas en este Aviso Legal, así como las relaciones entre el usuario y KOLIBIRD APP, se regirán e interpretarán de acuerdo con las leyes españolas. Para la solución de cualquier conflicto que pudiera suscitarse, el usuario y KOLIBIRD APP se someten expresamente a la competencia y jurisdicción de los juzgados y tribunales de la ciudad de MADRID, renunciando expresamente a cualquier otro fuero que pudiese corresponderles."
  },
  en: {
    title: "Legal Notice",
    paragraph1: "In compliance with art. 10 of Law 34/2002, of July 11, 2002, on Information Society Services and Electronic Commerce (LSSI+CE), we inform you: The company owner of the page https://kolibird.app",
    paragraph2: "Commercial Name: KOLIBIRD APP",
    paragraph3: "Registered Office: Vía de las Dos Castillas 7, 28224 Pozuelo de Alarcón",
    paragraph4: "Telephone: 675493668",
    paragraph5: "This Legal Notice is written in Spanish language",
    paragraph6: "Your Data",
    paragraph7: "The information or personal data provided by the user will be treated in accordance with the provisions of the Data Protection Policy. By using this website the user consents to the processing of such information and data and declares that all information or data provided are truthful and correspond to reality",
    paragraph8: "Privacy/Protection of personal data",
    paragraph9: "KOLIBIRD.APP complies with the guidelines General Data Protection Regulation (Regulation (EU) 2016/679) and other regulations in force at all times, and ensures the correct use and processing of the user's personal data.",
    paragraph10: "To do this, along with each form of collection of personal data, in the services that the user may request from Name of the company creating the website , will inform the user of the existence and acceptance of the particular conditions of the processing of their data in each case, informing the user of the responsibility of the file created, the address of the person responsible, the possibility of exercising their rights of access, rectification, cancellation or opposition, the purpose of processing and data communications to third parties where appropriate.",
    paragraph11: "Likewise, KOLIBIRD APP creator of the website informs that it complies with Law 34/2002 of July 11, 2002, of Services of the Information Society and Electronic Commerce and will request the user's consent to the processing of their email for commercial purposes at all times.",
    paragraph12: "Minors",
    paragraph13: "The contents of the Website are intended for persons of legal age. KOLIBIRD APP does not intend to obtain data from minors.",
    paragraph14: "Obligations of the user",
    paragraph15: "The User agrees to use the Web in accordance with the Law and this Legal Notice, as well as morality and good customs. To this end, the User will refrain from using the site for illegal or prohibited purposes, harmful to the rights and interests of third parties, or in any way damage, disable, overburden, impair or impede the normal use of computer equipment or documents, files and all types of content stored on any computer equipment of the provider",
    paragraph16: "In particular, and by way of example, but not exhaustively, the User undertakes not to transmit, disseminate or make available to third parties information, data, content, messages, graphics, drawings, sound or image files, photographs, recordings, software and, in general, any kind of material that:",
    paragraph17: "Is contrary to, despises or infringes fundamental rights and public liberties recognized in the Constitution, in international treaties and other regulations in force.",
    paragraph18: "Induces, incites or promotes criminal, denigrating, defamatory, violent or, in general, contrary to the law, morality and public order",
    paragraph19: "Induces, incites or promotes discriminatory actions, attitudes or thoughts based on sex, race, religion, beliefs, age or condition",
    paragraph20: "Is contrary to the right to honor, to personal or family intimacy or to one's own image",
    paragraph21: "In any way, damages the credibility of the provider or third parties",
    paragraph22: "Constitutes unlawful, misleading or unfair advertising",
    paragraph23: "Industrial and Intellectual Property",
    paragraph24: "KOLIBIRD APP itself or as assignee, owns all intellectual and industrial property rights of its website, as well as the elements contained therein (including but not limited to images, sound, audio, video, software or text; trademarks or logos, color combinations, structure and design, selection of materials used, computer programs necessary for its operation, access and use, etc.), owned by KOLIBIRD APP or its licensors.",
    paragraph25: "Pursuant to the provisions of Articles 8 and 32.1, second paragraph, of the Intellectual Property Law, the reproduction, distribution and public communication, including its mode of making available, of all or part of the contents of this website, for commercial purposes, in any medium and by any technical means, without the authorization of KOLIBIRD APP All rights reserved.",
    paragraph26: "The USER undertakes to respect the Intellectual and Industrial Property rights owned by KOLIBIRD APP. You can view the elements of the portal and even print, copy and store them on the hard drive of your computer or any other hardware provided it is solely and exclusively for your personal and private use. The USER shall refrain from deleting, altering, evading or manipulating any protection device or security system that was installed on the pages of KOLIBIRD APP",
    paragraph27: "Right of exclusion",
    paragraph28: "KOLIBIRD APP reserves the right to deny or withdraw access to the portal and/or the services offered without prior notice, at its own request or at the request of a third party, to those users who do not comply with the Terms of Use of the portal. KOLIBIRD APP will pursue the breach of the Conditions of Use of the portal, as well as any improper use of its portal exercising all civil and criminal actions that may correspond to it by law.",
    paragraph29: "Modifications and links",
    paragraph30: "KOLIBIRD APP reserves the right to make unannounced changes it deems appropriate on its website, may change, delete or add content and services it provides as well as the way in which they are presented or located, as well as in the General Terms and Conditions of Contract here",
    paragraph31: "KOLIBIRD APP is not responsible for the misuse of the contents of its website, being the sole responsibility of the person who accesses or uses them.",
    paragraph32: "In the event that https://kolibird.app/ contains links or hyperlinks to other Internet sites, KOLIBIRD APP creator of the website does not exercise any control over such sites and content. In no case KOLIBIRD APP creator of the website will assume any responsibility for the contents of any link belonging to another website, nor guarantee the technical availability, quality, reliability, accuracy, comprehensiveness, accuracy, validity and constitutionality of any material or information contained in any such hyperlinks or other Internet sites. Likewise, the inclusion of these external connections shall not imply any type of association, merger or participation with the connected entities.",
    paragraph33: "Applicable law and competent jurisdiction",
    paragraph34: "The conditions of use of the Web Page contained in this Legal Notice, as well as the relationship between the user and KOLIBIRD APP, shall be governed and interpreted in accordance with Spanish law. For the solution of any conflict that may arise, the user and KOLIBIRD APP expressly submit to the competence and jurisdiction of the courts and tribunals of the city of MADRID, expressly waiving any other jurisdiction that may correspond to them."
  }
}