import * as React from "react";
import Layout from "../components/layout/layout";
import { SeoES, NoIndex } from "../components/seo";
import "../assets/css/index.css";
import { translations } from "../locales/legalNotice";

const LegalNoticePage = () => {
  return (
    <Layout page="legal" language="es">
      <main className="page-container container">
        <div className="page">
          <h1 className="page-title">{translations.es.title}</h1>
          <p>{translations.es.paragraph1}</p>
          <p>{translations.es.paragraph2}</p>
          <p>{translations.es.paragraph3}</p>
          <p>{translations.es.paragraph4}</p>
          <p>Email: hi@kolibird.app</p>
          <p>{translations.es.paragraph5}</p>
          <p className="bold">{translations.es.paragraph6}</p>
          <p>{translations.es.paragraph7}</p>
          <p className="bold">{translations.es.paragraph8}</p>
          <p>{translations.es.paragraph9}</p>
          <p>{translations.es.paragraph10}</p>
          <p>{translations.es.paragraph11}</p>
          <p className="bold">{translations.es.paragraph12}</p>
          <p>{translations.es.paragraph13}</p>
          <p className="bold">{translations.es.paragraph14}</p>
          <p>{translations.es.paragraph15}</p>
          <p>{translations.es.paragraph16}</p>
          <ol>
            <li>{translations.es.paragraph17}</li>
            <li>{translations.es.paragraph18}</li>
            <li>{translations.es.paragraph19}</li>
            <li>{translations.es.paragraph20}</li>
            <li>{translations.es.paragraph21}</li>
            <li>{translations.es.paragraph22}</li>
          </ol>
          <p className="bold">{translations.es.paragraph23}</p>
          <p>{translations.es.paragraph24}</p>
          <p>{translations.es.paragraph25}</p>
          <p>{translations.es.paragraph26}</p>
          <p className="bold">{translations.es.paragraph27}</p>
          <p>{translations.es.paragraph28}</p>
          <p className="bold">{translations.es.paragraph29}</p>
          <p>{translations.es.paragraph30}</p>
          <p>{translations.es.paragraph31}</p>
          <p>{translations.es.paragraph32}</p>
          <p className="bold">{translations.es.paragraph33}</p>
          <p>{translations.es.paragraph34}</p>
        </div>
      </main>
    </Layout>
  );
};

export default LegalNoticePage;

export const Head = () => (
  <>
    <SeoES />
    <NoIndex />
  </>
);
